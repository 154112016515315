











































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import planStoreModule from "@/store/modules/plan";
import vendorPlanStoreModule from "@/store/modules/vendorPlan";
import { Business, Plan, Role, User } from "@/types";

const { mapGetters: planGetters, mapActions: planActions } =
  createNamespacedHelpers("AGIZO_PLANS");

const { mapActions: vendorPlanActions } =
  createNamespacedHelpers("VENDOR_PLAN");

export default Vue.extend<any, any, any, any>({
  name: "AgizoPlans",
  data: () => ({
    selectedPlan: undefined as undefined | Plan,
    showConfrimDialog: false,
  }),
  computed: {
    ...planGetters(["planPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    this.fetchPlanList();
  },
  methods: {
    ...planActions(["fetchPlanList"]),
    ...vendorPlanActions(["createVendorPlan", "updateVendorPlan"]),
    cancel() {
      this.$emit("cancel", false);
    },
    subscribe() {
      if (!this.selectedPlan) return;

      if (this.role.business.vendorPlan?._id) {
        this.updateVendorPlan({
          id: this.role.business.vendorPlan?._id,
          vendorPlan: {
            planId: this.selectedPlan?._id,
          },
        }).then((plan) => {
          if (plan) {
            this.showConfrimDialog = false;
          }
        });
        return;
      }

      this.createVendorPlan({
        businessId: (this.role.business as Business)._id,
        planId: this.selectedPlan?._id,
      }).then((plan) => {
        if (plan) {
          this.showConfrimDialog = false;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("AGIZO_PLANS")) {
      this.$store.registerModule("AGIZO_PLANS", planStoreModule);
    }
    if (!this.$store.hasModule("VENDOR_PLAN")) {
      this.$store.registerModule("VENDOR_PLAN", vendorPlanStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("AGIZO_PLANS");
    this.$store.unregisterModule("VENDOR_PLAN");
  },
});
