



















































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";
import { Billing, Role } from "@/types";
import CancelSubscriptionForm from "@/components/payment/CancelSubscriptionForm.vue";
import RegisterCardForm from "@/components/payment/RegsiterCardForm.vue";
import AgizoPlans from "@/components/payment/AgizoPlans.vue";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: paystackActions, mapGetters: paystackGetters } =
  createNamespacedHelpers("PAYSTACK_TRANSACTIONS");

export default Vue.extend({
  components: {
    CancelSubscriptionForm,
    RegisterCardForm,
    AgizoPlans,
  },
  name: "AhidiBillingV2",
  data: () => ({
    showDialog: false,
    billing: undefined as undefined | Billing,
    billingHeader: [
      { text: "Date", value: "createdAt" },
      // { text: "Type", value: "type" },
      { text: "Transaction ID", value: "id" },
      { text: "Amount", value: "amount" },
    ],
    options: {} as { page: number; itemsPerPage: number },
    showUpgradePlan: false,
    showRegisterCard: false,
    showCancelSubscription: false,
    plan: [],
    selectedCardId: "",
    currency: "KES",
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchBills();
      }
    },
    options: {
      handler() {
        this.fetchBills();
      },
      deep: true,
    },
  },
  filters: {
    fromDate: (date = new Date()) => {
      return moment(date).add(1, "months").tz(tz).format("DD/MM/Y h:mm a");
    },
  },
  computed: {
    ...paystackGetters(["transactionList"]),
    role(): Role {
      return this.$store.getters.role;
    },
    showCancelButton(): boolean {
      return (
        this.$store.getters.role.business.vendorPlan &&
        this.$store.getters.role.business.vendorPlan.plan.title ===
          "Ahidi Standard"
      );
    },
  },
  created() {
    this.fetchBills();
  },
  methods: {
    ...paystackActions(["fetchTransactionList"]),
    fetchBills() {
      if (this.role) {
        const customerId =
          this.role.business?.vendorPlan?.paystackCustomerId ?? 178666908;
        if (customerId) {
          const page = this.options.page ?? 1;
          const limit = this.options.itemsPerPage ?? 10;
          const params = `?customer=${customerId}&perPage=${limit}&page=${page}`;
          this.fetchTransactionList(params);
        }
      }
    },
    addPaymentMethod() {
      //this.showRegisterCard = false;
    },
    cancelledPlan(sub: any) {
      if (sub) {
        //
      }
      this.showCancelSubscription = false;
    },
    cardAdded() {
      //
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYSTACK_TRANSACTIONS")) {
      this.$store.registerModule("PAYSTACK_TRANSACTIONS", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYSTACK_TRANSACTIONS");
  },
});
