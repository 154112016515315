


































import { VendorPlan } from "@/types";
import Vue, { PropType } from "vue";

import paystackStoreModule from "@/store/modules/paystack";
import { createNamespacedHelpers } from "vuex";

const { mapActions: paystackActions } =
  createNamespacedHelpers("USUBSCRIBE_PLAN");
export default Vue.extend({
  name: "CancelSubscriptionForm",
  props: {
    plan: {
      type: Object as PropType<VendorPlan>,
      required: true,
    },
  },
  data: () => ({
    plansss: [],
  }),
  computed: {},

  methods: {
    ...paystackActions(["updateSubscription"]),
    cancelSub() {
      this.updateSubscription({
        status: "disable",
        subscriptionCode: this.plan.paystackSubscriptionCode,
      }).then((sub) => {
        this.$emit("cancel", sub);
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("USUBSCRIBE_PLAN")) {
      this.$store.registerModule("USUBSCRIBE_PLAN", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("USUBSCRIBE_PLAN");
  },
});
