








































































































import Vue from "vue";
import axios from "axios";
import { PHONE_REGEX } from "@/util/constants";
export default Vue.extend<any, any, any, any>({
  name: "RegisterCardForm",
  data: () => ({
    reveal: false,
    plans: [],
    cardNumber: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
    fullName: "",
    email: "",
    phone: "",
    pin: "",
    otp: "",
    valid: false,
    country: "",
    vendor_id: "",
    card_type: "",
    service_provider: "",
    items: ["Debit Card", "Credit Card"],
    serviceProviders: ["MasterCard", "Visa", "American Express"],
    cardNumberRules: [(v: string) => !!v || "Card number is required"],
    expiryMonthRules: [(v: string) => !!v || "Expiry month is required"],
    expiryYearRules: [(v: string) => !!v || "Expiry year is required"],
    fullNameRules: [(v: string) => !!v || "Full name is required"],
    emailRules: [(v: string) => !!v || "Email is required"],
    phoneRules: [
      (v: string) => !!v || "Phone is required",
      (v: string) =>
        !!PHONE_REGEX.test(v) || "A valid Phone number is required",
    ],
    pinRules: [(v: string) => !!v || "Pin is required"],
    serviceProviderRules: [
      (v: string) => !!v || "Service provider is required",
    ],
    // cardTypeRules:[(v: string) => !!v || "Card Type if required provider is required"],
    cvcRules: [(v: string) => !!v || "CVC is required"],
    cvc: "",
  }),
  mounted() {
    this.resetRegisterCardForm();
  },
  created() {
    this.fullName = this.$store.getters.user.fullName;
    this.email = this.$store.getters.user.email;
  },
  computed: {},

  methods: {
    cancel() {
      this.$emit("cancel", false);
    },

    validateRegisterCard() {
      const valid = (
        this.$refs.RegisterCardForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      /** deduct card */

      console.log("this.loggin user", this.$store.getters.user);
      console.log("selected card type", this.card_type);
      let cardNumber = this.cardNumber;
      cardNumber = cardNumber.replace(/ +/g, "");
      console.log("cardNumber", cardNumber);
      let payload = {
        card_no: cardNumber,
        country: "Kenya",
        vendor_id: this.$store.getters.user._id,
        // card_type:this.card_type,
        service_provider: this.service_provider,
        expiry_month: this.expiryMonth,
        expiry_year: this.expiryYear,
        name: this.fullName,
        email: this.email,
        phone: this.phone,
      };
      this.$emit("card-validated", {
        card: {
          number: cardNumber,
          exp_month: this.expiryMonth,
          exp_year: this.expiryYear,
          cvc: this.cvc,
        },
      });
      console.log("payload", payload);
      console.log("url", process.env.VUE_APP_PAYMENTS_API_V1_URL);
      axios
        .post(
          process.env.VUE_APP_PAYMENTS_API_V1_URL + `/payment/addCard`,
          payload
        )
        .then((response) => {
          console.log("response1", response);
          this.$store.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: response.data.data.message,
            },
            { root: true }
          );

          this.$emit("cardAdded", true);
          this.resetRegisterCardForm();
        })
        .catch((error) => {
          console.log("error", error);
          this.$store.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    resetRegisterCardForm() {
      this.cardNumber = "";
      this.expiryMonth = "";
      this.expiryYear = "";
      this.fullName = this.$store.getters.user.displayName;
      this.email = this.$store.getters.user.email;
      this.phone = "";
      this.pin = "";
      this.card_type = "";
      this.service_provider = "";
      (
        this.$refs.RegisterCardForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
});
