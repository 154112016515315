var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showUpgradePlan)?_c('div',{staticClass:"billing_area"},[_c('div',{staticClass:"custom_header"},[(false)?_c('v-card-title',[_vm._v(" Billing")]):_vm._e()],1),_c('div',{staticClass:"plan_area"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"950","elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"left_area"},[_c('div',[_c('v-card-subtitle',[_vm._v("Current Plan")]),(_vm.role.business.vendorPlan)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.role.business.vendorPlan.plan.title)+" ")]):_c('v-card-title',[_vm._v(" You don't have any current active plan. ")])],1),(_vm.role.business.vendorPlan.plan.title === 'Ahidi Flex')?_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.role.business.vendorPlan.plan.pricing .cardProcessingFeeInPercentage.business)+" % Card processing fee ")]),_c('p',[_vm._v(" "+_vm._s(_vm.role.business.vendorPlan.plan.pricing .mpesaChargeInPercentage.business)+" % Mpesa processing fee ")]),(
                    _vm.role.business.vendorPlan.plan.pricing.fixedFeePerMonth
                  )?_c('p',[_vm._v(" "+_vm._s(_vm.currency)+" "+_vm._s(_vm.role.business.vendorPlan.plan.pricing.fixedFeePerMonth)+" fixed fee per month ")]):_vm._e()]):_vm._e(),(
                  _vm.role.business.vendorPlan.plan.title === 'Ahidi Standard'
                )?_c('v-card-text',[_vm._v(" KES "+_vm._s(_vm.role.business.vendorPlan.plan.fixedFeePerMonth || 0)+" p.m ")]):_vm._e(),(
                  _vm.role.business.vendorPlan &&
                  _vm.role.business.vendorPlan.lastPaymentDate
                )?_c('v-card-subtitle',[_vm._v(" Next payment: "),_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm._f("fromDate")(_vm.role.business.vendorPlan.lastPaymentDate))+" ")])]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"right_area"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"#f48203","dark":""},on:{"click":function($event){_vm.showUpgradePlan = true}}},[_vm._v(" Update Plan ")])],1),(_vm.showCancelButton)?_c('div',{staticClass:"my-2"},[_c('v-btn',{on:{"click":function($event){_vm.showCancelSubscription = true}}},[_vm._v(" Cancel Subscription ")])],1):_vm._e(),_c('div',{staticClass:"my-2"},[_c('v-btn',{on:{"click":function($event){_vm.showRegisterCard = true}}},[_vm._v(" Add New Card ")])],1)])])],1),_c('v-divider'),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"left_area"},[_c('div',[_c('v-card-subtitle',[_vm._v("Payment Method")]),(
                    _vm.role.business.vendorPlan &&
                    _vm.role.business.vendorPlan.cardLast4
                  )?_c('v-card-title',[_c('div',{staticClass:"card_no"},[_c('span',[_vm._v("**** **** ****")]),_c('span',[_vm._v(_vm._s(_vm.role.business.vendorPlan.cardLast4))])])]):_vm._e()],1)])])],1)],1)],1),_c('div',{staticClass:"history_area",staticStyle:{"margin-top":"25px"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"950","elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"left_area"},[_c('div',[_c('v-card-title',[_vm._v("Billing History")])],1)])])],1),_c('v-divider'),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.billingHeader,"items":_vm.transactionList,"options":_vm.options,"item-key":"Date","no-data-text":"No bills to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(((item.currency || "KES") + " " + (new Number( item.amount / 100 ).toLocaleString())))+" ")]}}],null,true)})],1)],1)],1)],1)]):_vm._e(),(_vm.showUpgradePlan == true)?_c('agizo-plans',{on:{"cancel":function($event){_vm.showUpgradePlan = false},"upgraded":function($event){return _vm.upgradedPlan()},"subscription-created":function($event){_vm.showUpgradePlan = false}}}):_vm._e(),_c('v-dialog',{attrs:{"content-class":"elevation-0","max-width":"400"},model:{value:(_vm.showCancelSubscription),callback:function ($$v) {_vm.showCancelSubscription=$$v},expression:"showCancelSubscription"}},[(_vm.role && _vm.role.business)?_c('div',[_c('CancelSubscriptionForm',{attrs:{"plan":_vm.role.business.vendorPlan},on:{"canceld":_vm.cancelledPlan}})],1):_vm._e()]),_c('v-dialog',{attrs:{"content-class":"elevation-0","max-width":"450"},model:{value:(_vm.showRegisterCard),callback:function ($$v) {_vm.showRegisterCard=$$v},expression:"showRegisterCard"}},[_c('RegisterCardForm',{on:{"cancel":function($event){_vm.showRegisterCard = false},"cardAdded":function($event){return _vm.cardAdded()},"upgraded":function($event){return _vm.upgradedPlan()},"card-validated":_vm.addPaymentMethod}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }